import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { v4 as uuidv4 } from 'uuid';

const createDiscoveryTree = (payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'CREATING_DISCOVERY_TREE' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(`/us/dt/discovery-tree`, payload, {
            params: {
              requestId: reqId,
            },
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              // 'x-api-version': 'v2',
            },
          });
          if (response.status === 200) {
            await dispatch({
              type: 'CREATING_DISCOVERY_TREE_DONE',
            });
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'CREATING_DISCOVERY_TREE_FAILED',
              payload: `Failed to create discovery tree`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'CREATING_DISCOVERY_TREE_FAILED',
            payload: `Failed to create discovery tree`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'CREATING_DISCOVERY_TREE_FAILED',
        payload: `Failed to create discovery tree`,
      });
    }
  };
};

export default createDiscoveryTree;
