import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fixed } from 'layouts';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Tooltip,
  Radio,
  Checkbox,
  ListItemText,
  FormLabel,
  FormGroup,
  Card,
  Typography,
  TextField,
  OutlinedInput,
  Chip,
  Modal,
  InputAdornment,
  CircularProgress,
  Icon,
  Divider,
  DialogContentText,
  RadioGroup,
  Grid,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
  Tabs,
  Tab,
} from '@mui/material';
import moment from 'moment';
import {
  Add,
  ArrowBack,
  ChevronRight,
  Close,
  CopyAll,
  Delete,
  Edit,
  ExpandMore,
  Group,
  Link,
  Lock,
  Mic,
  Notes,
  Redo,
  Replay,
  Search,
  Send,
  Settings,
  StopCircle,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import getAllUsers from 'redux/actions/Common/getAllUsers';
import RichTextEditor from 'components/RichTextEditor';
import * as yup from 'yup';
import { useFormik } from 'formik';
import updatePlaybookForUser from 'redux/actions/Common/updatePlaybookForUser';
import getAllOrganizations from 'redux/actions/Common/getAllOrganizations';
import updateTeamMemberRole from 'redux/actions/Common/updateTeamMemberRole';
import updateOrganization from 'redux/actions/Common/updateOrganization';
import { capitalizeText, validateEmail } from 'utils';
import sendTeamInvite from 'redux/actions/Common/sendTeamInvite';
import getPlans from 'redux/actions/Common/getPlans';
import updatePlanForOrganization from 'redux/actions/Common/updatePlanForOrganization';
import refreshUsage from 'redux/actions/Common/refreshUsage';
import refreshUsageForOrg from 'redux/actions/Common/refreshUsageForOrg';
import updatePlanForOrgUser from 'redux/actions/Common/updatePlanForOrgUser';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import updatePlanTrialEndsOn from 'redux/actions/Common/updatePlanTrialEndsOn';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import getContentScoringConfigTemplate from 'redux/actions/Common/getContentScoringConfigTemplate';
import deepEqual from 'deep-equal';
import getBotsByUserId from 'redux/actions/Common/getBotsByUserId';
import addCreditsForOrg from 'redux/actions/Common/addCreditsForOrg';
import { v4 as uuidv4 } from 'uuid';
import getAllDiscoveryTree from 'redux/actions/Common/getAllDiscoveryTree';
import createDiscoveryTree from 'redux/actions/Common/createDiscoveryTree';
import QuestionFrameworkTree from 'components/@2024/QuestionFrameworkTree';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OrganizationsQuestionFramework = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const { version } = useParams();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const {
    tree,
    fetchingPagination,
    emptyList,
    fetching,
    lastTime,
  } = useSelector((state) => state.discoveryTree);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [currentTab, setCurrentTab] = useState('framework');
  const [showAddOrganization, setShowAddOrganization] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [websiteURL, setWebsiteURL] = useState('');

  const [promptOrder, setPromptOrder] = useState(null);
  const [selectedTree, setSelectedTree] = useState(null);
  const [selectedMetadata, setSelectedMetadata] = useState(null);
  const [promptType, setPromptType] = useState('after_state');

  const handleListScroll = (e, paginate) => {
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        tree &&
        tree.length % 10 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      dispatch(getAllDiscoveryTree(10, lastTime, () => {}));
    }
  };

  useEffect(() => {
    if (selectedOrg && tree && tree.length > 0) {
      setSelectedTree(
        tree.find((item) => item.id === selectedOrg)?.topics || [],
      );
      setPromptOrder(
        tree.find((item) => item.id === selectedOrg)?.rootTopics || [],
      );
      setSelectedMetadata(
        tree.find((item) => item.id === selectedOrg)?.metadata || {},
      );
    }
  }, [selectedOrg, tree]);

  useEffect(() => {
    dispatch(getAllDiscoveryTree(10, null, () => {}));
  }, []);

  // console.log('tree', selectedTree);

  return (
    <Fixed>
      <Box
        width={{ xs: '100vw', sm: '100vw', md: 'calc(100vw - 266px)' }}
        height={{
          xs: 'calc(100vh - 60px)',
          sm: 'calc(100vh - 70px)',
          md: 'calc(100vh - 80px)',
        }}
        sx={{
          overflow: 'hidden',
        }}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'column' }}
        position={'relative'}
        gap={1}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
          sx={{ position: 'relative' }}
        >
          {/* <Box
            sx={{
              position: 'absolute',
              background: '#fff',
              p: 2,
              zIndex: 12,
            }}
          >
            <form onSubmit={formikSearch.handleSubmit}>
              <TextField
                label="Search"
                variant="outlined"
                name={'searchKeyword'}
                size="small"
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                value={formikSearch.values.searchKeyword}
                onChange={(e) => {
                  formikSearch.setFieldValue('searchKeyword', e.target.value);
                }}
              />
            </form>
          </Box> */}
          <Box
            sx={{
              position: 'absolute',
              background: '#fff',
              p: 2,
              zIndex: 12,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setShowAddOrganization(true)}
            >
              Add Organization
            </Button>
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 1 }}
            display={'flex'}
            flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
            alignItems={{ xs: 'start', sm: 'start', md: 'start' }}
            gap={2}
            sx={
              isXs || isSm
                ? {
                    borderBottom: '1px solid #d3d3d3',
                    padding: 1,
                    overflowX: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                  }
                : {
                    borderRight: '1px solid #d3d3d3',
                    minHeight: 'calc(100vh - 80px)',
                    maxHeight: 'calc(100vh - 80px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                      // outline: '1px solid slategrey',
                    },
                    padding: 2,
                    paddingTop: 10,
                  }
            }
          >
            {fetching ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <CircularProgress size={16} />
                <Typography>Loading tree ...</Typography>
              </Box>
            ) : (
              tree?.map((tree) => (
                <Box
                  // flex={1}
                  key={tree?.id}
                  sx={{
                    // flex: 1,
                    width: 1,
                    padding: '8px 16px',
                    border: '1px solid #d3d3d3',
                    borderRadius: 2,
                    cursor: 'pointer',
                    background:
                      selectedOrg === tree?.id
                        ? theme.palette.primary.main
                        : 'default',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() => setSelectedOrg(tree?.id)}
                >
                  <Typography
                    variant="body1"
                    color={'text.primary'}
                    fontWeight={'bold'}
                    sx={{
                      color: selectedOrg === tree?.id ? '#fff' : 'text.primary',
                    }}
                  >
                    {tree?.title}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    // fontWeight={'bold'}
                    sx={{
                      color:
                        selectedOrg === tree?.id ? '#fff' : 'text.secondary',
                    }}
                  >
                    {tree?.url}
                  </Typography>
                </Box>
              ))
            )}
            {fetchingPagination ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  color="primary"
                  disabled
                >
                  Loading more tree ...
                  <CircularProgress size={20} />
                </Button>
              </Box>
            ) : emptyList === false && tree?.length >= 15 ? (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  textAlign: 'center',
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleListScroll(e, true)}
                  sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 100,
                  }}
                >
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box
            flex={{ xs: 'auto', sm: 'auto', md: 4 }}
            sx={{
              position: 'relative',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={2}
              // justifyContent={'space-between'}
              p={2}
              sx={{
                minHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
                maxHeight:
                  isXs || isSm ? 'calc(100vh - 130px)' : 'calc(100vh - 80px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                paddingBottom: isXs || isSm ? 0 : 0,
              }}
            >
              {tree &&
              tree.length > 0 &&
              tree?.find((tree) => tree?.id === selectedOrg)?.status ===
                'pending' ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: 300,
                    gap: 2,
                  }}
                >
                  <CircularProgress size={20} />
                  <Typography>Tree generation is in progress</Typography>
                </Box>
              ) : (
                <>
                  {/* {version === 'v2' ? ( */}
                  <Tabs
                    value={currentTab}
                    onChange={(e, newValue) => {
                      setCurrentTab(newValue);
                    }}
                    aria-label="Tabs"
                    orientation={'horizontal'}
                    // indicatorColor={theme.palette.background.default}
                    variant="scrollable"
                    // scrollButtons={!isMd}
                    allowScrollButtonsMobile
                    sx={{ border: '1px solid #d3d3d3' }}
                  >
                    <Tab
                      value={'framework'}
                      label={
                        <Typography variant="caption" fontWeight={'bold'}>
                          Question Framework
                        </Typography>
                      }
                      id={`simple-tab-${0}`}
                      aria-controls={`simple-tabpanel-${0}`}
                      sx={{}}
                    />
                    <Tab
                      value={'prompts'}
                      label={
                        <Typography variant="caption" fontWeight={'bold'}>
                          Prompts
                        </Typography>
                      }
                      id={`simple-tab-${5}`}
                      aria-controls={`simple-tabpanel-${5}`}
                      sx={{}}
                    />
                  </Tabs>
                  {/* ) : null} */}
                  {selectedOrg ? (
                    currentTab === 'prompts' ? (
                      <Box display={'flex'} flexDirection={'column'} gap={2}>
                        {promptOrder && promptOrder?.length > 0 ? (
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={2}
                          >
                            <FormControl size="small" sx={{ minWidth: 150 }}>
                              <InputLabel id="promptType">
                                Prompt Type
                              </InputLabel>
                              <Select
                                variant="outlined"
                                size="small"
                                labelId={'promptType'}
                                label={'Words Limit'}
                                id={'promptType'}
                                name={'promptType'}
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => {
                                  setPromptType(e.target.value);
                                }}
                                value={promptType}
                              >
                                {promptOrder.map((key) => (
                                  <MenuItem key={key} value={key}>
                                    <Typography variant="subtitle2">
                                      {capitalizeText(
                                        key?.split('_').join(' '),
                                      )}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 2,
                                gap: 2,
                                border: '1px solid #d3d3d3',

                                minHeight: 'calc(100vh - 240px)',
                                maxHeight: 'calc(100vh - 240px)',
                                overflowY: 'scroll',
                                '&::-webkit-scrollbar': {
                                  width: '5px',
                                  height: '5px',
                                },
                                '&::-webkit-scrollbar-track': {
                                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                  webkitBoxShadow:
                                    'inset 0 0 6px rgba(0,0,0,0.00)',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  backgroundColor: theme.palette.primary.main,
                                  borderRadius: 2,
                                  // outline: '1px solid slategrey',
                                },
                              }}
                            >
                              {selectedMetadata?.[promptType]?.messages?.map(
                                (msg, index) => (
                                  <>
                                    {msg ? (
                                      <Box
                                        key={msg?.content}
                                        sx={{
                                          borderTopLeftRadius:
                                            msg?.role === 'user' ? 16 : 0,
                                          borderTopRightRadius:
                                            msg?.role === 'user' ? 0 : 16,
                                          borderBottomLeftRadius:
                                            msg?.role === 'user' ? 8 : 16,
                                          borderBottomRightRadius:
                                            msg?.role === 'user' ? 16 : 8,
                                          maxWidth: '80%',
                                          alignSelf:
                                            msg?.role === 'user'
                                              ? 'end'
                                              : 'start',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          // gap: 1,
                                          boxShadow: theme.shadows[8],
                                          background:
                                            msg?.role === 'user'
                                              ? theme.palette.primary.main
                                              : '#fff',
                                        }}
                                      >
                                        <Box
                                          display={'flex'}
                                          alignItems={'center'}
                                          justifyContent={'space-between'}
                                          sx={{
                                            padding: '8px 16px',
                                            gap: 4,
                                          }}
                                        >
                                          {msg?.role === 'user' ? (
                                            <Typography
                                              variant="caption"
                                              sx={{
                                                color:
                                                  msg?.role === 'user'
                                                    ? '#f1f1f1'
                                                    : theme.palette.text
                                                        .secondary,
                                              }}
                                            >
                                              {'Prompt'}
                                            </Typography>
                                          ) : (
                                            <Typography
                                              variant="caption"
                                              sx={{
                                                color:
                                                  msg?.role === 'user'
                                                    ? '#f1f1f1'
                                                    : theme.palette.text
                                                        .secondary,
                                              }}
                                            >
                                              {'GPT'}
                                            </Typography>
                                          )}
                                          {/* <Typography
                                            variant="caption"
                                            sx={{
                                              color:
                                                msg?.role === 'user'
                                                  ? '#f1f1f1'
                                                  : theme.palette.text
                                                      .secondary,
                                            }}
                                          >
                                            {moment(msg?.createdOn).format(
                                              'DD MMMM YYYY hh:mm:ss A',
                                            )}
                                          </Typography> */}
                                        </Box>
                                        <Typography
                                          sx={{
                                            whiteSpace: 'pre-wrap',
                                            color:
                                              msg?.role === 'user'
                                                ? '#fff'
                                                : theme.palette.text.primary,
                                            padding: '0px 16px 16px 16px',
                                          }}
                                        >
                                          {msg?.content}
                                        </Typography>
                                      </Box>
                                    ) : null}
                                  </>
                                ),
                              )}
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              minHeight: 300,
                              gap: 2,
                            }}
                          >
                            <Typography>No meta data found</Typography>
                          </Box>
                        )}
                      </Box>
                    ) : currentTab === 'framework' ? (
                      <Box display={'flex'} flexDirection={'column'} gap={2}>
                        {selectedTree &&
                        Object.keys(selectedTree).length > 0 ? (
                          <QuestionFrameworkTree data={selectedTree} />
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              minHeight: 300,
                              gap: 2,
                            }}
                          >
                            <Typography>No question tree found</Typography>
                          </Box>
                        )}
                      </Box>
                    ) : null
                  ) : null}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={showAddOrganization}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Add Organization</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              minWidth: 300,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                Type
              </Grid>
              <Grid item xs={8}>
                <TextField
                  sx={{ minWidth: 300 }}
                  label="Organization Name"
                  variant="outlined"
                  name={'organizationName'}
                  size="small"
                  // fullWidth
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                Website URL
              </Grid>
              <Grid item xs={8}>
                <TextField
                  sx={{ minWidth: 300 }}
                  label="Website URL"
                  variant="outlined"
                  name={'websiteURL'}
                  size="small"
                  // fullWidth
                  value={websiteURL}
                  onChange={(e) => setWebsiteURL(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              onClick={(e) => {
                setShowAddOrganization(false);
              }}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                dispatch(
                  createDiscoveryTree(
                    {
                      title: organizationName,
                      url: websiteURL,
                    },
                    (data) => {
                      dispatch(getAllDiscoveryTree(10, null, () => {}));
                      setShowAddOrganization(false);
                    },
                  ),
                );
              }}
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fixed>
  );
};

export default OrganizationsQuestionFramework;
